import React, { useState, FC, ReactNode } from "react";
import { Box } from "@chakra-ui/react";
import * as consts from "src/shared/consts";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { Drawer } from "./components/Drawer";

export interface LayoutProps {
  children?: ReactNode;
  headerContent?: ReactNode;
}

export const Layout: FC<LayoutProps> = (props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        minH={"100vh"}
        color={consts.COLOR_DARK_BLUE}
        bg={
          "linear-gradient(180deg, #F1FEFF 0%, #EDF7FF 23.44%, #FDFDFF 48.44%, #ECF6FF 69.27%, #FFFCFE 85.94%, #E7F3FF 100%)"
        }
      >
        <Header content={props.headerContent} onToggleDrawer={() => setIsDrawerOpen((v) => !v)} />
        <Box flex={1} position={"relative"} display={"flex"} flexDirection={"column"}>
          <Box pos={"absolute"} inset={0} overflow={"hidden"}>
            <Box
              position={"absolute"}
              right={0}
              top={"150px"}
              w={"368px"}
              h={"368px"}
              borderRadius={"50%"}
              bg={"linear-gradient(267.05deg, rgba(0, 240, 255, 0.5) 1.74%, rgba(74, 45, 255, 0.2) 92.16%)"}
              filter={"blur(70px)"}
            />
            <Box
              position={"absolute"}
              left={0}
              top={"1000px"}
              w={"368px"}
              h={"368px"}
              borderRadius={"50%"}
              bg={"linear-gradient(267.05deg, rgba(255, 255, 255, 0.5) 1.74%, rgba(252, 135, 219, 0.2) 92.16%)"}
              filter={"blur(70px)"}
            />
          </Box>
          <Box as="main" flex={1} zIndex={1} display={"flex"} flexDirection={"column"} height={"100%"}>
            {props.children}
          </Box>
          <Footer />
        </Box>
      </Box>
      <Drawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />
    </>
  );
};
