import React, { FC } from "react";
import { Wrap, Box } from "@chakra-ui/react";
import SharedNextTranslateSwitchLang from "src/shared/components/Controls/SharedNextTranslateSwitchLang";
import { TiltedPlus } from "src/shared/components/Shapes";
import { HeaderLogoLink } from "../../HeaderLogoLink";

export interface TopProps {
  onClose: () => void;
}

export const Top: FC<TopProps> = (props) => {
  return (
    <Wrap direction="row" minH="127px" align="center" justify="space-between">
      <HeaderLogoLink />
      <SharedNextTranslateSwitchLang />
      <Box as="button" onClick={props.onClose}>
        <TiltedPlus boxSize="26px" />
      </Box>
    </Wrap>
  );
};
