import React from "react";
import { Stack, Link } from "@chakra-ui/react";
import * as Texts from "src/shared/components/Texts";
import * as consts from "src/shared/consts";
import SharedNextTranslateLink from "src/shared/components/Controls/SharedNextTranslateLink";

export type LinkType = {
  text: string;
  href: string;
  isExternal?: boolean;
};

export interface LinkItemProps extends Object {
  title: string;
  links: Array<LinkType>;
}

export const LinkItem = (section: LinkItemProps) => {
  return (
    <Stack>
      <Texts.Normal color={consts.COLOR_DARK_BLUE} mb="12px">
        {section.title}{" "}
      </Texts.Normal>
      {section.links.map((row) => {
        return (
          <Texts.Normal key={row.text} maxW="153px" cursor="pointer" mb={"8px"} color={consts.COLOR_MEDIUM_BLUE}>
            {row.isExternal ? (
              <Link href={row.href} isExternal={row.isExternal}>
                {row.text}
              </Link>
            ) : (
              <SharedNextTranslateLink href={row.href}>{row.text}</SharedNextTranslateLink>
            )}
          </Texts.Normal>
        );
      })}
    </Stack>
  );
};
