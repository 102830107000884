import React, { FC } from "react";
import { Drawer as Drawer_, DrawerOverlay, DrawerContent, Stack } from "@chakra-ui/react";
import { Top } from "./components/Top";
import { Nav } from "./components/Nav";
import { Info } from "./components/Info";
import { Connexion } from "./components/Connexion";
import { Social } from "./components/Social";

interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export const Drawer: FC<DrawerProps> = (props) => {
  return (
    <Drawer_ size="full" isOpen={props.isOpen} onClose={props.onClose} placement="top">
      <DrawerOverlay>
        <DrawerContent bg="white" padding={"25px"}>
          <Stack>
            <Top onClose={props.onClose} />
            <Nav />
            <Info />
            <Stack align="center" spacing="75px">
              <Connexion />
              <Social />
            </Stack>
          </Stack>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer_>
  );
};
