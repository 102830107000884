import React from "react";
import Router from "next-translate/Router";
import { TextProps } from "@chakra-ui/react";
import * as consts from "src/shared/consts";
import * as Texts from "src/shared/components/Texts";

export interface HeaderTextLinkProps extends TextProps {
  href: string;
  accented?: boolean;
  callback?: () => void;
}

export const HeaderTextLink = (props: HeaderTextLinkProps) => {
  const { href, accented, callback, ...textProps } = props;
  return (
    <Texts.H5M
      {...textProps}
      cursor={"pointer"}
      color={accented ? consts.COLOR_DARK_BLUE : consts.COLOR_MEDIUM_BLUE}
      _hover={{ textDecoration: "underline" }}
      onClick={() => {
        Router.pushI18n(href);
        callback?.();
      }}
    />
  );
};
