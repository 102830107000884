import React from "react";
import NextImage from "next/image";
import useTranslation from "next-translate/useTranslation";
import { Box } from "@chakra-ui/react";
import * as Texts from "src/shared/components/Texts";
import * as consts from "src/shared/consts";
import { LinkList } from "./components/LinkList";

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <Box as="footer" zIndex={0}>
      <Box
        display={"flex"}
        alignItems={"start"}
        flexDirection={{ base: "column", sm: "row" }}
        px={{ base: "25px", md: "140px" }}
        py={{ base: "50px", md: "80px" }}
      >
        <Box w={"116px"} h={"53px"} mr={{ base: 0, sm: "50px", md: "200px" }} mb={{ base: "60px", sm: 0 }}>
          <NextImage src="/svg/udini-logo-dental-ai.svg" alt="Udini Dental AI" width={"116px"} height={"53px"} />
        </Box>
        <LinkList />
      </Box>
      <Box
        display={"flex"}
        h={"70px"}
        alignItems={"center"}
        justifyContent={"center"}
        color={"white"}
        bg={consts.COLOR_RED}
      >
        <Texts.Small>© {t("shared:copyright")}</Texts.Small>
      </Box>
    </Box>
  );
};
