import React, { FC } from "react";
import { IconButton } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";

export interface BurgerControlProps {
  onClick: () => void;
}

export const BurgerControl: FC<BurgerControlProps> = (props) => {
  return (
    <IconButton
      aria-label="Open drawer"
      variant="transparent"
      fontSize="25px"
      icon={<HamburgerIcon />}
      onClick={props.onClick}
    />
  );
};
