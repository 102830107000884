import React from "react";
import { Text, Wrap } from "@chakra-ui/react";

export const Info = () => {
  return (
    <Wrap px="30px" py="70px" direction="row" justify="space-evenly">
      <Text fontFamily="Montserrat" fontSize="14px" fontWeight="bold" lineHeight="17px">
        meet@udini.co
      </Text>
    </Wrap>
  );
};
