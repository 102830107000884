import React from "react";
import useTranslation from "next-translate/useTranslation";
import { Flex, Stack, Text, TextProps } from "@chakra-ui/react";
import SharedNextTranslateLink from "src/shared/components/Controls/SharedNextTranslateLink";
import { UDINI_HOME_ROUTE, UDINI_NEWS_ROUTE, UDINI_PRODUCTS_SMILE_ROUTE } from "src/utils/constants/routes";

export const Nav = () => {
  const { t } = useTranslation();
  return (
    <Flex justify="center" marginBottom="50px">
      <Stack align="center" spacing="25px">
        <SharedNextTranslateLink href={UDINI_HOME_ROUTE} passHref>
          <NavText>{t("shared:linkHome")}</NavText>
        </SharedNextTranslateLink>
        <SharedNextTranslateLink href={UDINI_HOME_ROUTE + "#tech"} passHref>
          <NavText>{t("shared:linkAiaas")}</NavText>
        </SharedNextTranslateLink>
        <SharedNextTranslateLink href={UDINI_PRODUCTS_SMILE_ROUTE}>
          <NavText>{t("shared:linkProductsSmile")}</NavText>
        </SharedNextTranslateLink>
        <SharedNextTranslateLink href={UDINI_NEWS_ROUTE}>
          <NavText>{t("shared:linkNews")}</NavText>
        </SharedNextTranslateLink>
      </Stack>
    </Flex>
  );
};

const NavText = (props: TextProps) => (
  <Text
    {...props}
    as="a"
    minHeight={{ base: "29px", sm: "43px" }}
    fontSize={{ base: "24px", sm: "35px" }}
    fontWeight="bold"
    color="black"
    lineHeight={{ base: "29px", sm: "43px" }}
    cursor="pointer"
    fontFamily="Montserrat"
    _active={{ color: "brandPink.100" }}
    _focus={{ color: "brandPink.100" }}
    _hover={{ color: "brandPink.100" }}
  />
);
