import React from "react";
import i18Config from "i18n";
import useTranslation from "next-translate/useTranslation";
import { HStack } from "@chakra-ui/react";
import * as consts from "src/shared/consts";
import * as Texts from "src/shared/components/Texts";
import { useAuthDispatch } from "src/shared/contexts/AuthContext";
import { rerouteToLang } from "src/utils/constants/routes";

const SharedNextTranslateSwitchLang = () => {
  const { lang } = useTranslation();
  const { setPrefLang } = useAuthDispatch();

  const handleClick = (value: string) => {
    setPrefLang(value);
    rerouteToLang(value);
  };

  return (
    <HStack spacing={"10px"}>
      {i18Config.allLanguages.map((value) => {
        const isActive = value === lang;
        return (
          <Texts.H5M
            key={value}
            color={isActive ? consts.COLOR_RED : consts.COLOR_MEDIUM_BLUE}
            cursor={isActive ? "default" : "pointer"}
            onClick={!isActive ? () => handleClick(value) : undefined}
            _focus={{ outline: "none !important" }}
            _hover={{ textDecoration: value !== lang ? "underline" : undefined }}
          >
            {value.toUpperCase()}
          </Texts.H5M>
        );
      })}
    </HStack>
  );
};

export default SharedNextTranslateSwitchLang;
