import React from "react";
import { Stack } from "@chakra-ui/react";
import {
  SMILE_HOME_ROUTE,
  SMILE_SIGNIN_ROUTE,
  UDINI_HOME_ROUTE,
  UDINI_PRODUCTS_SMILE_ROUTE,
} from "src/utils/constants/routes";
import { useAuth } from "src/shared/contexts/AuthContext";
import useTranslation from "next-translate/useTranslation";
import { ButtonLink } from "src/shared/components/Button";
import { HeaderTextLink } from "../../HeaderTextLink";

export const Connexion = () => {
  const [{ isAuthenticated }, { disconnect }] = useAuth();
  const { t } = useTranslation();
  return (
    <Stack align="center" direction="column" spacing="20px">
      <HeaderTextLink
        href={isAuthenticated ? UDINI_HOME_ROUTE : SMILE_SIGNIN_ROUTE}
        callback={isAuthenticated ? disconnect : undefined}
      >
        {isAuthenticated ? t("shared:logout") : t("shared:login")}
      </HeaderTextLink>
      <ButtonLink href={isAuthenticated ? SMILE_HOME_ROUTE : `${UDINI_PRODUCTS_SMILE_ROUTE}#pricing`}>
        {isAuthenticated ? t("shared:gotoSmile") : t("shared:testSmile")}
      </ButtonLink>
    </Stack>
  );
};
