import React from "react";
import useTranslation from "next-translate/useTranslation";
import { HStack } from "@chakra-ui/react";
import { useAuth } from "src/shared/contexts/AuthContext";
import { SMILE_SIGNIN_ROUTE, UDINI_HOME_ROUTE } from "src/utils/constants/routes";
import { HeaderTextLink } from "../../HeaderTextLink";

export const Connexion = () => {
  const [{ isAuthenticated }, { disconnect }] = useAuth();
  const { t } = useTranslation();
  return (
    <HStack spacing="20px">
      <HeaderTextLink
        href={isAuthenticated ? UDINI_HOME_ROUTE : SMILE_SIGNIN_ROUTE}
        accented={true}
        callback={isAuthenticated ? disconnect : undefined}
      >
        {isAuthenticated ? t("shared:logout") : t("shared:login")}
      </HeaderTextLink>
    </HStack>
  );
};
