import React from "react";
import { Grid } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import { LinkItem } from "./LinkItem";
import * as routes from "src/utils/constants/routes";
import { UDINI_SOCIAL_LINKEDIN } from "src/utils/constants/udini";

export const LinkList = () => {
  const { t } = useTranslation();
  const data = [
    {
      title: t("shared:footerGroupExplore"),
      links: [
        { text: t("shared:linkHome"), href: routes.UDINI_HOME_ROUTE },
        { text: t("shared:linkProductsSmile"), href: routes.UDINI_PRODUCTS_SMILE_ROUTE },
      ],
    },
    {
      title: t("shared:footerGroupContact"),
      links: [{ text: t("shared:linkContact"), href: routes.UDINI_CONTACT_ROUTE }],
    },
    {
      title: t("shared:footerGroupFollow"),
      links: [{ text: t("shared:linkLinkedIn"), href: UDINI_SOCIAL_LINKEDIN, isExternal: true }],
    },
    {
      title: t("shared:footerGroupLegal"),
      links: [
        { text: t("shared:linkTermsAndConditions"), href: routes.UDINI_TERMS_AND_CONDITIONS_ROUTE },
        { text: t("shared:linkTermsOfUse"), href: routes.UDINI_TERMS_OF_USE_ROUTE },
      ],
    },
  ];

  return (
    <Grid
      flexGrow={{ base: 0, sm: 1 }}
      rowGap={{ base: "25px", sm: "20px" }}
      gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
    >
      {data.map((col) => (
        <LinkItem key={col.title} title={col.title} links={col.links} />
      ))}
    </Grid>
  );
};
