import React from "react";
import SharedNextTranslateLink from "src/shared/components/Controls/SharedNextTranslateLink";
import { UDINI_HOME_ROUTE } from "src/utils/constants/routes";
import { Box } from "@chakra-ui/react";
import Image from "next/image";

export const HeaderLogoLink = () => {
  return (
    <SharedNextTranslateLink href={UDINI_HOME_ROUTE}>
      <Box cursor="pointer" objectFit="contain" w={{ base: "85px", sm: "168px" }} display={"flex"}>
        <Image src={"/svg/udini-logo-dental-ai.svg"} alt="Udini Dental AI" width="168px" height="78px" priority />
      </Box>
    </SharedNextTranslateLink>
  );
};
