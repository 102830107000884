import React from "react";
import { Flex, Image, Wrap } from "@chakra-ui/react";
import { UDINI_SOCIAL_LINKEDIN } from "src/utils/constants/udini";

const SOCIALS_LINKS = [
  {
    href: UDINI_SOCIAL_LINKEDIN,
    img: "/svg/drawer/linkedin.svg",
    alt: "linkedin",
  },
];

export const Social = () => {
  return (
    <Wrap direction="row" spacing="35px" justify="center">
      {SOCIALS_LINKS.map((socialImg) => (
        <Flex key={socialImg.alt} as="a" href={socialImg.href} target="_blank" cursor="poiner" w="30px">
          <Image src={socialImg.img} alt="facebook" />
        </Flex>
      ))}
    </Wrap>
  );
};
