import React, { FC, ReactNode } from "react";
import { Box, Flex } from "@chakra-ui/react";
import SharedNextTranslateSwitchLang from "src/shared/components/Controls/SharedNextTranslateSwitchLang";
import { HeaderLogoLink } from "../HeaderLogoLink";
import { BurgerControl } from "./components/BurgerControl";
import { Connexion } from "./components/Connexion";
import { Nav } from "./components/Nav";

export interface HeaderProps {
  content?: ReactNode;
  onToggleDrawer: () => void;
}

export const Header: FC<HeaderProps> = (props) => {
  return (
    <Box as="header" pos={"relative"}>
      <Box minH={{ base: "100px", md: "170px" }}>{props.content}</Box>
      <Box
        pos={"absolute"}
        top={0}
        w={"100%"}
        h={{ base: "100px", md: "170px" }}
        display={"flex"}
        alignItems={"center"}
        justifyContent={{ base: "space-evenly", md: "space-between" }}
        px={{ base: "15px", md: "80px" }}
      >
        <Box
          pos={"absolute"}
          inset={0}
          display={{ base: "none", md: "flex" }}
          alignItems={"center"}
          justifyContent={"center"}
          zIndex={0}
        >
          <Nav />
        </Box>
        <Flex zIndex={1}>
          <HeaderLogoLink />
        </Flex>
        <Flex display={{ base: "none", md: "flex" }} flex={1} justify="center" marginX="20px" whiteSpace="nowrap" />
        <Flex display={{ base: "none", md: "flex" }} justify="flex-end" whiteSpace="nowrap" zIndex={1}>
          <Connexion />
        </Flex>
        <Flex
          flex="1"
          align="center"
          justify="flex-end"
          display={{ base: "flex", md: "none" }}
          minW={{ base: "50px", md: "200px" }}
          cursor="pointer"
          zIndex={1}
        >
          <Flex align="center" display={{ base: "flex", md: "none" }} mr={"25px"}>
            <SharedNextTranslateSwitchLang />
          </Flex>
          <BurgerControl onClick={props.onToggleDrawer} />
        </Flex>
        <Flex ml="45px" align="center" justify="flex-end" display={{ base: "none", md: "flex" }} zIndex={1}>
          <SharedNextTranslateSwitchLang />
        </Flex>
      </Box>
    </Box>
  );
};
